.quiz-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.go-back-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.go-back-button:hover {
    background-color: #0056b3;
}

.quiz-info p {
    margin: 0;
}

.question-container {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.question-text {
    font-weight: bold;
    margin-bottom: 10px;
}

.answer {
    padding: 5px;
    border-radius: 4px;
    margin-bottom: 5px;
}

.correct-answer {
    background-color: #d4edda;
    color: #155724;
}

.wrong-answer {
    background-color: #f8d7da;
    color: #721c24;
}

.missing-answer {
    background-color: #fff3cd;
    color: #856404;
}