@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* || VARIABLES */

:root {
  /* COLORS */
  --BGCOLOR: #151c2e;
  --BORDER-COLOR: #FFF;
  --COLOR: #FFF;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: whitesmoke;
  --LINK-HOVER: rgba(245, 245, 245, 0.9);
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: #FFF;
  --TABLE-COLOR: #000;
  --INPUT-INVALID: #F00;
  --BORDER-COLOR: #3258a8;

  /* Quiz */
  --QUIZ-QUESTION: #57550c;
  --LEVEL-COLOR: red;
}

/* || DASH HEADER, CONTAINER & FOOTER */

.dash-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--BGCOLOR);
  padding: 0 0.5em;
  border-bottom: 1px solid var(--BORDER-COLOR);
  text-decoration: none;
}

.dash-header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

/* .dash-header__container--small {
  max-width: 800px;
} */

.dash-header__nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 0.5em;
}

/* Styles for the container of the logo and title */
.logo-title {
  display: flex;
  align-items: center; /* Vertically center the items */
  text-decoration: none; /* Remove underline from the link */
}

/* Styles for the logo image */
.logo {
  height: 50px; /* Adjust the height as needed */
  margin-right: 10px; /* Space between the logo and title */
}

/* Adjust the title text if necessary */
.dash-header__title h1 {
  font-size: 1.5em; /* Adjust the font size as needed */
  margin: 0; /* Remove default margins */
}

@media (max-width: 600px) {
  .logo {
      height: 30px; /* Smaller logo on small screens */
  }

  .dash-header__title h1 {
      font-size: 1em; /* Smaller text on small screens */
  }
}

.dash-container {
  padding: 0.75em 0.5em 0.5em;
  flex-grow: 1;
}

.dash-footer {
  font-size: 1rem;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: var(--BGCOLOR);
  padding: 0.5em;
  border-top: 1px solid var(--BORDER-COLOR);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 1em;
  color: var(--COLOR);
}

.dash-footer__button {
  font-size: 1rem;
  width: auto;
  height: auto;
}

.dash-header__title {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--COLOR);
  text-decoration: none;
}

/* Icons */
.icon-button {
  width: 48px;
  height: 48px;
  font-size: 2.5rem;
  background-color: transparent;
  border: none;
  color: var(--COLOR);
  display: grid;
  place-content: center;
}

.icon-button:hover,
.icon-button:focus-visible {
  transform: scale(1.2);
}

.icon-button[disabled] {
  visibility: hidden;
}

.dash-header__nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 0.5em;
  right: 0;
  position: absolute;
}

@media (max-width: 920px) {
  .dash-header__title {
    font-size: 1em;
    /* Smaller font size for mobile */
    white-space: nowrap;
    /* Prevent line breaks */
    overflow: hidden;
    /* Hide overflowing text */
    text-overflow: ellipsis;
    /* Add ellipsis if text overflows */
  }

  .icon-button {
    width: 24px;
    /* Smaller width for mobile */
    height: 24px;
    /* Smaller height for mobile */
    font-size: 1.25rem;
    /* Smaller icon size */
  }


}

/* || UTILITY CLASSES */
.errmsg {
  display: inline-block;
  background-color: var(--TABLE-BGCOLOR);
  color: var(--ERROR);
  padding: 0.25em;
  margin-bottom: 0.5em;
}

.errmsg a:any-link {
  color: var(--ERROR);
  text-decoration: underline;
}

/* List */
.list__edit-button {
  padding: 0.25rem 0.5rem;
  border-radius: 15%;
  border: 1px solid var(--BORDER-COLOR);
  background-color: rgb(47, 75, 165);
  color: white;
  cursor: pointer;
}

.list__search {
  position: relative;

}

/* Welcome Page */
.welcome__name {
  font-size: 1.5em;
  font-weight: bold;
  color: darksalmon;
  text-align: center;
  margin-top: 1rem;
}

.welcome__functions {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 2rem;
}

.welcome__functions__h2 {
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(136, 32, 32);
  text-align: center;
}

.welcome__functions__buttons {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1em;
  margin-top: 2rem;
}

.welcome__functions__buttons a {
  text-decoration: none;
  color: rgb(136, 32, 32);
  border-radius: 10%;
  border: 2px solid rgb(136, 32, 32);
  padding: 0.5em 1em;
  text-align: center;
  display: block;
}

.welcome__functions__buttons a:hover {
  background-color: rgba(136, 32, 32, 0.1);
}

.welcome__functions__buttons__button {
  text-decoration: none;
  color: rgb(136, 32, 32);
  border-radius: 10%;
  border: 2px solid rgb(136, 32, 32);
  padding: 0.5em 1em;
  text-align: center;
  display: block;
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
}

.welcome__functions__buttons__button:hover {
  background-color: rgba(136, 32, 32, 0.1);
}


.questions_import::file-selector-button {
  text-decoration: none;
  color: rgb(136, 32, 32);
  border-radius: 10%;
  border: 2px solid rgb(136, 32, 32);
  padding: 0.5em 1em;
  text-align: center;
}

/* Responsive Design */
@media (min-width: 600px) {
  .welcome__functions {
    flex-flow: column nowrap;
    align-items: center;
  }

  .welcome__functions__buttons {
    flex-flow: row nowrap;
    gap: 1em;
  }

  .welcome__functions__buttons p {
    margin: 0;
  }
}


/* Quiz */
/* Flex horizontal and put content in center. it's only one column*/
.quiz {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
}

.quiz__main {
  width: 100%;
  max-width: 60rem;
  box-sizing: border-box;
}

.quiz__main-section {
  border: 1px solid black;
  padding: 1rem;
  box-sizing: border-box;
}

.quiz__main-title {
  margin-left: 1rem;
}

.quiz__main-question {
  font-size: 1.1em;
  font-weight: bold;
  color: var(--QUIZ-QUESTION);
  margin: 1rem;
  max-width: 100%;
}

.quiz__main-answers {
  margin-left: 1rem;
}

.test {
  margin: 1rem;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice>span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown>p {
  margin: 0;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.timer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

.timer__main {
  /* width: 60rem; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

/* Finished Message */
.finished {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  margin: 2rem;
}

.finished__main {
  /* width: 60rem; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

.finished__main-level {
  text-align: center;
}

.finished__main-level--color {
  color: var(--LEVEL-COLOR);
  font-size: 30px;
}

@media (max-width: 768px) {
  .quiz__main {
    width: 100%;
    padding: 1rem;
  }

  .quiz__main-section {
    padding: 0.5rem;
  }

  .quiz__main-title {
    margin-left: 0.5rem;
  }

  .quiz__main-question {
    font-size: 1em;
    margin: 0.5rem;
  }

  .quiz__main-answers {
    margin-left: 0.5rem;
  }

  .finished__main-level--color {
    font-size: 1.5rem;
  }
}

.blink {
  color: white;
  -webkit-animation: blink 800ms step-end infinite;
  animation: blink 800ms step-end infinite;
}

@-webkit-keyframes blink {
  50% {
    color: red;
  }
}

@keyframes blink {
  50% {
    color: red;
  }
}

/* Quiz View */
.list__view-button {
  padding: 0.25rem 0.5rem;
  border-radius: 15%;
  border: 1px solid var(--BORDER-COLOR);
  background-color: rgb(47, 75, 165);
  color: white;
  cursor: pointer;
  margin-right: 0.25rem;
}

.row--unfinished {
  background-color: #ffcccc;
  /* Light red background */
}

.logout_button {
  margin-right: 1rem;
}

.failed-questions-container {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #f44336;
  border-radius: 5px;
  background-color: #ffe6e6;
}

.failed-questions-list {
  list-style-type: none;
  padding: 0;
}

.failed-question-item {
  font-size: 16px;
  color: #d32f2f;
  padding: 5px 0;
  border-bottom: 1px solid #f44336;
}

.failed-question-item:last-child {
  border-bottom: none;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.custom-file-upload {
  padding: 10px 20px;
  border: 1px solid #2196f3;
  border-radius: 5px;
  background-color: #e3f2fd;
  cursor: pointer;
  color: #0d47a1;
  font-size: 16px;
  display: inline-block;
}

.custom-file-upload:hover {
  background-color: #bbdefb;
}

.active-questions-container {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #2196f3;
  border-radius: 5px;
  background-color: #e3f2fd;
}

.active-questions-list {
  list-style-type: none;
  padding: 0;
}

.active-question-item {
  font-size: 16px;
  color: #0d47a1;
  padding: 5px 0;
  border-bottom: 1px solid #2196f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active-question-item button {
  padding: 5px 10px;
  background-color: #1976d2;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.active-question-item button:hover {
  background-color: #1565c0;
}

.inactive-row {
  background-color: rgba(255, 0, 0, 0.1); /* Light red background */
}
