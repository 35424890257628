/* Modal styles */
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    max-height: 85vh;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    padding: 30px;
    overflow-y: auto;
  }
  
  .modal-title {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
  }
  
  .modal-question {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .modal-answers-title {
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .modal-answers-list {
    list-style-type: none; /* Remove numbering */
    padding-left: 0;
    margin-bottom: 20px;
  }
  
  .modal-answer-item {
    font-size: 0.95rem;
    margin-bottom: 5px;
  }
  
  .modal-correct-answer {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
  }
  
  /* Table header styles */
  .table-header {
    background-color: #1976d2;
    color: white;
    font-weight: bold;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .modal-content {
      padding: 20px;
    }
  
    .modal-title {
      font-size: 1.2rem;
    }
  
    .modal-question,
    .modal-answers-title,
    .modal-correct-answer {
      font-size: 0.95rem;
    }
  
    .modal-answer-item {
      font-size: 0.9rem;
    }
  }
  
  /* Optional: Customize the backdrop */
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  /* Table container root */
  .table-root {
    height: calc(100vh - 100px); /* Adjust 64px based on your top bar height */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Hide extra scrollbars */
  }
  