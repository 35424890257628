.quiz-info-container {
    width: 80%;
    max-width: 1000px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Arial', sans-serif;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .quiz-info-container h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .quiz-info-container ul {
    /* background: #f0f0f0; */
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
    width: 100%;
  }
  
  .quiz-info-container ul li {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    text-align: left;
    font-size: 16px;
  }
  
  .start-button {
    background-color: #1E90FF;
    color: white;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 25%;
  }
  
  .start-button:hover {
    background-color: #125ba3;
  }
  
  .button-disabled {
    background-color: #1E90FF;
    color: white;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 25%;
    opacity: 0.5;
    pointer-events: none;
  }
  
  @media (max-width: 600px) {
    .start-button {
      font-size: 12px;
      /* Adjust this to achieve the desired text size */
      width: 100%;
      /* Make the button take 100% of the container width if needed */
    }
  
    .button-disabled {
      font-size: 12px;
      /* Adjust this to achieve the desired text size */
      width: 100%;
      /* Make the button take 100% of the container width if needed */
    }
  }