/* Removed global body styles */

.public-wrapper {
    height: 100vh;
    overflow: hidden; /* Prevents scrolling within the wrapper */
}

.public {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px); /* Adjust height to account for navbar and margin */
    overflow: hidden; /* Prevents the scroll bar on the main container */
}

.public__header {
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 20px;
    flex-shrink: 0; /* Prevents the header from shrinking */
}

.public__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden; /* Prevents overflow scroll on main */
    padding-bottom: 60px; /* Adds padding to avoid overlap with the button */
}

.public__text-container {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 20px;
    padding: 10px;
    text-align: justify;
    max-height: 75vh; /* Increased height to take more screen space */
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box; /* Ensures padding is included in height calculation */
}

.public__text-container p {
    margin: 16px 0; /* Adjust margin to preserve space between paragraphs */
    line-height: 1.5; /* Adjust line-height for better readability */
}

.start-button-public {
    background-color: #1E90FF;
    color: white;
    width: calc(100% - 40px); /* Adjusted width to fit within the screen, accounting for padding */
    max-width: 50%; /* Added max-width to ensure it doesn't stretch too far */
    padding: 10px 20px;
    margin: 10px auto !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0.3s ease;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
}

.start-button-public.disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.start-button-public.enabled:hover {
    background-color: #125ba3;
}

@media (max-width: 600px) {
    .start-button-public {
        font-size: 12px;
        width: calc(100% - 20px); /* Further reduce the width on small screens to avoid cutoff */
        max-width: 95%; /* Maintain a max-width for smaller screens */
    }

    .public__text-container {
        max-height: 70vh; /* Adjust for smaller screens */
    }
}
